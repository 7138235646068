import React, { useEffect, useRef, useState } from "react";
import Binance from "../../../assets/images/Binance_black.png";
import BayBit from "../../../assets/images/Bybit_black.png";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setResponseLoading,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
  setConnectionFailMessage,
  setBinanceConnecting,
} from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { updateUser } from "../../../redux/users/usersSlice";
import ExchangeConnects from "./ExchangeConnects";
import copyIcon from "../../../assets/images/Copy.svg";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import RenderLinks from "../../../components/links/RenderLinks";
import AuthLoader from "../../../components/loader/authLoader";
import DeleteMember from "../../../components/modals/DeleteMember";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import UpdateAndVerifyAccountOTP from "../accountSettings/UpdateAndVerifyAccountOTP";
import {
  deleteIcon,
  wifiConnect,
  wifiDisconnect,
} from "../copyTradeCm/Connection";

function Connection({ setTab }) {
  const user = useSelector((value) => value?.user?.user);
  const [showConnection, setShowConnection] = useState(true);
  const [view, setView] = useState("connect");
  const [callSubmit, setCallSubmit] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [credentials, setCredentials] = useState({
    exchange: "",
    apiKey: "",
    secretKey: "",
  });
  const [selectedConnectionType, setSelectedConnectionType] = useState("");
  const dispatch = useDispatch();
  const api = useApi();
  const loaders = useSelector((state) => state?.loader);
  const inputRef = useRef(null);
  const [showOtpPopUp, setShowOtpPopUp] = useState({
    show: false,
    buttonClick: "",
  });

  const [showDisConnectApiKey, setShowDisConnectApiKey] = useState({
    show: false,
    apiObj: null,
  });

  const [showDeleteApiKey, setShowDeleteApiKey] = useState({
    show: false,
    apiObj: null,
  });
  const [alredayConnect, setAlredayConnect] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScroll(false);
  }, [scroll]);

  const liveUrl = true;

  const oauthBinance = async () => {
    if (
      user?.binanceCredentials?.apiKey &&
      user?.binanceCredentials?.secretKey
    ) {
      getUserCredentials("Binance", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }
      dispatch(setBinanceConnecting(true));
      const clientId = "39vZ9un1Fd";
      const redirect_uri = liveUrl
        ? "https%3A%2F%2Fmy.copyyy.it%2Fsignin"
        : "https%3A%2F%2Fdev.copyyy.it%2Fsignin";
      // const redirect_uri = "https%3A%2F%2Fdev.copyyy.it%2Fsignin";
      const scope = "user:openId,create:apikey";
      const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
      window.location.href = cUrl;
    }
  };

  const oauth = async () => {
    if (user?.bybitCredentials?.apiKey && user?.bybitCredentials?.secretKey) {
      getUserCredentials("ByBit", "callSubmit");
    } else {
      let userAgent = navigator?.userAgent;
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(true));
      }

      localStorage.setItem("binanceCondition", true);

      const clientId = "3ccf41551815af4";
      const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
      window.location.href = cUrl;
    }
  };

  const getUserCredentials = async (event, hint) => {
    let exchangeKey = "";
    if (event === "Binance") {
      exchangeKey = "binance";
    } else if (event === "ByBit") {
      exchangeKey = "bybit";
    }

    if (
      (event === "ByBit" && !user?.bybitCredentials?.apiKey) ||
      (event === "Binance" && !user?.binanceCredentials?.apiKey)
    ) {
      // No credentials available for the selected exchange, don't call the API
      setCredentials((prev) => ({
        ...prev,
        apiKey: "",
        secretKey: "",
        exchange: event,
      }));
    } else {
      // Credentials are available, update the exchange and call the API
      const updatedCredentials = {
        ...credentials,
        exchange: event,
      };
      setCredentials(updatedCredentials);

      dispatch(setLoading(true));
      const response = await api(
        "get",
        `binanceCredentials/index/${exchangeKey}`
      );

      if (response?.status) {
        let data = response?.data;
        if (data?.length === 3) {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            // secretPhrase: data?.secretPhrase,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        } else {
          setCredentials({
            apiKey: data?.apiKey,
            secretKey: data?.secretKey,
            exchange: data?.exchange,
          });
          if (hint === "callSubmit") {
            setCallSubmit(true);
          }
        }
      } else {
        setCredentials({
          apiKey: "",
          secretKey: "",
          exchange: event,
        });
      }
    }
    dispatch(setLoading(false));
  };

  // useEffect(() => {
  //     dispatch(setResponseLoading(true));
  // }, []);

  useEffect(() => {
    if (callSubmit) {
      submit();
    }
  }, [callSubmit]);

  const switchToApiKeyView = () => {
    setScroll(true);
    setSelectedConnectionType("");
    setShowConnection((prevShowConnection) => !prevShowConnection);
    setView((prevView) => (prevView === "connect" ? "apiKeys" : "connect"));
    setCredentials({
      apiKey: "",
      secretKey: "",
      exchange: "",
    });
  };

  const handleNavigate = () => {
    if (user?.onboarding?.connect) {
      setTab("payment");
    }
  };

  const submit = async () => {
    dispatch(setResponseLoading(true));

    if (!credentials?.exchange) {
      dispatch(setResponseLoading(false));
      missingPopup("Exchange");
    } else if (!credentials?.apiKey) {
      dispatch(setResponseLoading(false));
      missingPopup("API Key");
    }
    // else if (!credentials?.secretPhrase) missingPopup("Secret Phrase");
    else if (!credentials?.secretKey) {
      dispatch(setResponseLoading(false));
      missingPopup("Secret Key");
    } else {
      const response = await api("post", "binanceCredentials/create", {
        ...credentials,
        // isConnected: true,
      });
      dispatch(setResponseLoading(false));
      if (response?.status) {
        // toastify("Connected to exchange successfully.", "success");

        dispatch(updateUser(response?.data));
        dispatch(setShowConnectionSuccessModal(true));
        if (user?.firstVisit) {
          setTimeout(async () => {
            if (user?.role === "member") {
              dispatch(setCurrentTab("subscribe"));
              await api("post", "users/update", {
                onboardingStep: "subscribe",
              });
            }
            if (user?.role === "leader") {
              dispatch(setCurrentTab("payment"));
              await api("post", "users/update", {
                onboardingStep: "payment",
              });
            }
          }, 2000);
        }
        if (!callSubmit) {
          switchToApiKeyView();
        } else {
          setCallSubmit(false);
        }
      } else {
        setCallSubmit(false);

        // toastify(response?.message);
        dispatch(setConnectionFailMessage(response?.message));
        dispatch(setShowConnectionFailModal(true));
      }
    }
  };

  const disconnect = async () => {
    dispatch(setLoading(true));

    let credentialToUpdate = {};
    if (user?.binanceCredentials?.isConnected) {
      credentialToUpdate = {
        binanceCredentials: {
          ...user?.binanceCredentials,
          isConnected: false,
          isConnectedSpot: false,
          isConnectedUsdFuture: false,
        },
        onboarding: {
          ...user.onboarding,
          connect: false,
        },
      };
    }

    if (user?.bybitCredentials?.isConnected) {
      credentialToUpdate = {
        bybitCredentials: {
          ...user?.bybitCredentials,
          isConnected: false,
          isConnectedSpot: false,
          isConnectedDerivatives: false,
        },
        onboarding: {
          ...user.onboarding,
          connect: false,
        },
      };
    }

    if (credentialToUpdate) {
      const response = await api("post", "users/update", credentialToUpdate);

      if (response?.status) {
        dispatch(updateUser(response?.data?.user));
        if (user?.role === "leader")
          await api("post", "livetrade/closeAllMembersTrade");
        toastify("Exchange disconnected successfully.", "success");
      } else {
        toastify(response?.message);
      }
    }

    dispatch(setLoading(false));
  };

  const handleExchangeChange = (selectedValue) => {
    setSelectedConnectionType(selectedValue?.value);
    setCredentials((prev) => ({
      ...prev,
      exchange: selectedValue.value,
    }));
    getUserCredentials(selectedValue.value);
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.className = "hidden-input";
    tempInput.focus();

    setTimeout(() => {
      tempInput?.remove();
      if (inputRef.current) {
        inputRef?.current?.focus();
        // const end = inputRef.current.value.length;
        // inputRef.current.setSelectionRange(end, end);
      }
    }, 0);
  };

  const handleErrorModal = () => {
    dispatch(setShowConnectionFailModal(false));
    dispatch(setConnectionFailMessage(""));
  };

  useEffect(() => {
    const handleBinanceConnection = async () => {
      const BinanceConnecting = localStorage.getItem("binanceCondition");

      if (BinanceConnecting) {
        try {
          const myResponse = await api("get", "users/index");
          if (myResponse) {
            dispatch(updateUser(myResponse?.data?.user));
          }

          setTimeout(async () => {
            const response2 = await api("get", "users/index");
            if (response2) {
              dispatch(updateUser(response2?.data?.user));
            }
          }, 300);

          setTimeout(() => {
            dispatch(
              updateUser({
                ...user,
                binanceCredentials: {
                  ...user.binanceCredentials,
                  isConnected: false,
                },
              })
            );
            localStorage.removeItem("binanceCondition");
            dispatch(setLoading(false));
          }, 100);
          setTimeout(() => {
            dispatch(
              updateUser({
                ...user,
                binanceCredentials: {
                  ...user.binanceCredentials,
                  isConnected: false,
                },
              })
            );
            localStorage.removeItem("binanceCondition");
            dispatch(setLoading(false));
          }, 400);
          setTimeout(() => {
            dispatch(
              updateUser({
                ...user,
                binanceCredentials: {
                  ...user.binanceCredentials,
                  isConnected: false,
                },
              })
            );
            localStorage.removeItem("binanceCondition");
            dispatch(setLoading(false));
          }, 500);
        } catch (error) {
          dispatch(setLoading(false)); // Ensure loading is turned off even if there's an error
        }
      }
    };

    handleBinanceConnection();
  }, []);

  const fetchUserData = async () => {
    const myResponse = await api("get", "users/index");
    if (myResponse) {
      dispatch(updateUser(myResponse?.data?.user));
    }
  };

  const callApi = async (buttonClick) => {
    if (showOtpPopUp?.buttonClick === "ByBit" || buttonClick === "ByBit") {
      user?.bybitCredentials?.isConnected ? disconnect() : oauth();
    } else if (
      showOtpPopUp?.buttonClick === "Binance" ||
      buttonClick === "Binance"
    ) {
      user?.binanceCredentials?.isConnected ? disconnect() : oauthBinance();
    } else if (showOtpPopUp?.buttonClick === "DeleteApiKey") {
      await api("post", "binanceCredentials/update", {
        ...showOtpPopUp.apiObj,
      });
      fetchUserData();
    } else {
      submit();
    }
  };

  return (
    <React.Fragment>
      {showConnection && view === "connect" && (
        <div className="connection connectionJs pb-sm-50px">
          {loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : (
            !localStorage.getItem("binanceCondition") && (
              <>
                <div className="title">
                  {user?.binanceCredentials?.isConnected ||
                  user?.bybitCredentials?.isConnected ? (
                    <>
                      <h3>Connected Trading Account</h3>
                      <p>
                        Your members are automatically copy trading everything
                        you trade from your connected trading account
                      </p>
                      <p className="sub-inner-heading">
                        If you want to switch exchange account
                      </p>
                      <p>
                        Disconnect your connected exchange account and choose
                        another exchange. Please make sure to close your trades
                        so your subscribers won’t be affected by your changes..
                      </p>
                    </>
                  ) : (
                    <>
                      <h3>Connect Your Trading Account</h3>
                      <p>
                        Your members will automatically be copy trading
                        everything you trade from the account you’re connected
                        with.
                      </p>
                    </>
                  )}
                </div>
                <div className="connectionBlock">
                  <div
                    className={`accountCard ${
                      user?.binanceCredentials?.isConnected ? "active" : ""
                    } `}
                  >
                    <div className="account">
                      <img src={Binance} alt="binance" />
                      <div className="d-flex align-items-center">
                        <p>
                          API Status:
                          <span
                            className={
                              user?.binanceCredentials?.isConnectedUsdFuture
                                ? "cyan ms-1"
                                : "red ms-1"
                            }
                          ></span>
                        </p>
                      </div>
                    </div>

                    <div className="accountButtonBox">
                      <button
                        className={`${
                          user?.bybitCredentials?.isConnected
                            ? "cursorNotAllowed"
                            : ""
                        }`}
                        onClick={() => {
                          if (user?.bybitCredentials?.isConnected) {
                            setAlredayConnect(true);
                          } else if (
                            user.firstVisit ||
                            !user?.binanceCredentials?.isConnected
                          ) {
                            callApi("Binance");
                          } else {
                            setShowDisConnectApiKey({
                              show: true,
                              buttonClick: "Binance",
                            });
                          }
                        }}
                      >
                        {user?.binanceCredentials?.isConnected
                          ? wifiDisconnect()
                          : wifiConnect()}
                        {user?.binanceCredentials?.isConnected
                          ? "Disconnect"
                          : "Connect"}
                      </button>

                      {user?.binanceCredentials?.isConnected && (
                        <button
                          onClick={() => {
                            setShowDeleteApiKey({
                              show: true,
                              apiObj: {
                                binanceCredentials: {},
                                uniqueIds: {},
                              },
                            });
                          }}
                          className="black"
                        >
                          {deleteIcon()}
                          Delete API
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accountCard ${
                      user?.bybitCredentials?.isConnected ? "active" : ""
                    }`}
                  >
                    <div className="account">
                      <img src={BayBit} alt="ByBit" />
                      <div className="d-flex align-items-center">
                        <p>
                          API Status:
                          <span
                            className={
                              user?.bybitCredentials?.isConnectedDerivatives
                                ? "cyan ms-1"
                                : "red ms-1"
                            }
                          ></span>
                        </p>
                      </div>
                    </div>
                    <div className="accountButtonBox">
                      {user?.bybitCredentials?.isConnected && (
                        <button
                          onClick={() => {
                            setShowDeleteApiKey({
                              show: true,
                              apiObj: {
                                bybitCredentials: {},
                              },
                            });
                          }}
                          className="black"
                        >
                          {deleteIcon()}
                          Delete API
                        </button>
                      )}
                      <button
                        className={`${
                          user?.binanceCredentials?.isConnected
                            ? "cursorNotAllowed"
                            : ""
                        }`}
                        onClick={() => {
                          if (user?.binanceCredentials?.isConnected) {
                            setAlredayConnect(true);
                          } else if (
                            user.firstVisit ||
                            !user?.bybitCredentials?.isConnected
                          ) {
                            callApi("ByBit");
                          } else {
                            setShowDisConnectApiKey({
                              show: true,
                              buttonClick: "ByBit",
                            });
                          }
                        }}
                      >
                        {wifiConnect()}
                        {user?.bybitCredentials?.isConnected
                          ? "Disconnect"
                          : "Connect"}
                      </button>
                    </div>
                  </div>

                  <div className="apiInsted">
                    {!user?.binanceCredentials?.isConnected &&
                      !user?.bybitCredentials?.isConnected && (
                        <h3 onClick={switchToApiKeyView}>
                          &gt; Use API keys instead
                        </h3>
                      )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      )}
      {!showConnection && view === "apiKeys" && (
        <div className="connection connectionJs pb-sm-50px">
          {loaders?.awaitingResponse ? (
            <AuthLoader component="connection" />
          ) : loaders?.showConnectionSuccessModal ? (
            <AuthLoader component="connectionsuccess" />
          ) : loaders?.showConnectionFailModal ? (
            <AuthLoader
              component="connectionfail"
              handleErrorModal={handleErrorModal}
              connectionFailMessage={loaders?.connectionFailMessage}
            />
          ) : (
            <>
              <div className="title">
                {user?.binanceCredentials?.isConnected ||
                user?.bybitCredentials?.isConnected ? (
                  <>
                    <p>
                      Your members are automatically copy trading everything you
                      trade from this specific connected trading account.
                    </p>
                    <p className="sub-inner-heading">Want to switch account?</p>
                    <p>
                      Please hit ‘disconnect’ on your connected account and
                      choose another. Please make sure to inform your
                      subscribers that you’ve switched as they’ll need to switch
                      too.
                    </p>
                  </>
                ) : (
                  <>
                    <h3>Connect Your Trading Account</h3>
                    <p>
                      Your members will automatically be copy trading everything
                      you trade from the account you’re connected with.
                    </p>
                  </>
                )}
              </div>
              <div className="connectionBlock">
                <div className="title mt-5 mb-3">
                  <h3>Select your exchange(s) to connect</h3>
                </div>
                <ExchangeConnects
                  onExchangeChange={handleExchangeChange}
                  credentials={credentials}
                />
                {selectedConnectionType && (
                  <>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          ref={inputRef}
                          type="text"
                          className="form-control"
                          id="apiKeys"
                          placeholder="API Key"
                          value={credentials?.apiKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              apiKey: e.target.value,
                            }))
                          }
                        />
                        <label for="apiKeys">Api Key</label>
                      </div>
                    </div>
                    <div className="messageInput">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="secretKey"
                          placeholder="Secret Key"
                          value={credentials?.secretKey}
                          onChange={(e) =>
                            setCredentials((prev) => ({
                              ...prev,
                              secretKey: e.target.value,
                            }))
                          }
                        />
                        <label for="secretKey">Secret Key</label>
                      </div>
                    </div>
                    {credentials?.exchange !== "ByBit" && (
                      <>
                        <p className="mt-4">
                          <strong>Important:</strong> your exchange requires you
                          to use our IP address for the API setup.{" "}
                        </p>
                        <div className="url p-0">
                          <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                            <input
                              type="text"
                              className="form-control fs14 regular"
                              value="108.129.27.187"
                              disabled
                            />
                            <span className="position-absolute">
                              <img
                                className="cursor-pointer"
                                onClick={() => {
                                  const communityIPAddress = "108.129.27.187";

                                  navigator.clipboard
                                    .writeText(communityIPAddress)
                                    .then(() => {
                                      toastify(
                                        "IP Address copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      toastify(
                                        "Failed to copy community link to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="apiInsted">
                  <h3 onClick={switchToApiKeyView}>&gt; Use Quick connect</h3>
                  {credentials.apiKey && credentials.secretKey && (
                    <button
                      className="btn cyan-btn fs13"
                      onClick={() => {
                        if (user.firstVisit) {
                          callApi();
                        } else {
                          setShowOtpPopUp({ show: true });
                        }
                      }}
                    >
                      {user?.firstVisit ? "Next" : "Save Changes"}
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {showDisConnectApiKey?.show && (
        <DeleteMember
          yesAction={() => {
            setShowOtpPopUp({
              show: true,
              buttonClick: showDisConnectApiKey.buttonClick,
            });

            setShowDisConnectApiKey({
              show: false,
              buttonClick: null,
            });
          }}
          noAction={() => {
            setShowDisConnectApiKey({
              show: false,
              buttonClick: null,
            });
          }}
          text="You’re about to disconnect your exchange."
          subtext="Your subscribers trades will be closed and your copy trading service won’t work."
          anotherText={
            "Do you want to close all members trades and disconnect?"
          }
          yes={"Yes"}
          no={"No"}
        />
      )}
      {showOtpPopUp?.show && (
        <UpdateAndVerifyAccountOTP
          setShowOtpPopUp={setShowOtpPopUp}
          callApi={callApi}
        />
      )}
      {alredayConnect && (
        <DeleteMember
          yesAction={() => {
            setAlredayConnect(false);
          }}
          text="You need to disconnect"
          subtext="you can’t add a 2nd exchange before you’ve disconnected current exchange"
          noneclass="d-none"
        />
      )}
      {showDeleteApiKey.show && (
        <DeleteMember
          yesAction={() => {
            setShowOtpPopUp({
              show: true,
              buttonClick: "DeleteApiKey",
              apiObj: showDeleteApiKey.apiObj,
            });

            setShowDeleteApiKey({
              show: false,
              apiObj: null,
            });
          }}
          noAction={() => {
            setShowDeleteApiKey({
              show: false,
              apiObj: null,
            });
          }}
          text="Are you Sure you want to delete you keys permanantly"
          subtext="Next time you’ll need to manually add your Api keys to connect your account"
        />
      )}
      <RenderLinks page="copyTradeConnect" />
    </React.Fragment>
  );
}

export default Connection;
