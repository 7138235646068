import React, { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Introduction from "./Introduction";
import Connection from "./Connection";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import Subscribe from "./Subscribe";
import { setResponseLoading } from "../../../redux/loading/loadingSlice";
import { setCurrentTab } from "../../../redux/links/linksSlice";

function Index({ setCommunity }) {
  // const [currentTab, setCurrentTab] = useState("introduction");
  const { NP_id } = useParams();

  const user = useSelector((value) => value?.user?.user);
  const { currentTab } = useSelector((state) => state.links);

  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [user]);

  useEffect(() => {
    if (id) {
      dispatch(setCurrentTab("subscribe"));
    }
  }, [id]);

  useEffect(() => {
    if (NP_id) {
      dispatch(setCurrentTab("subscribe"));
    }
  }, [NP_id]);

  useEffect(() => {
    if (state) {
      const { tab, connect } = state;

      if (tab) {
        dispatch(setCurrentTab(tab));
      }

      if (connect) {
        dispatch(setResponseLoading(true));
      }
    }
  }, [state]);

  const handleTabSelect = (selectedTab) => {
    if (
      (selectedTab === "subscribe" && !user?.onboarding?.connect) ||
      (selectedTab === "riskManagement" && !user?.onboarding?.subscribed)
    ) {
      return;
    }

    // Update the current tab
    dispatch(setCurrentTab(selectedTab));
  };

  const handleCurrentTab = (tab) => {
    dispatch(setCurrentTab(tab));
  };

  useEffect(() => {
    if (
      !["introduction", "connection", "subscribe", "payment"].includes(
        currentTab
      )
    ) {
      dispatch(setCurrentTab("connection"));
    }
  }, []);

  return (
    <div
      className={`${
        currentTab == "subscribe" ? "billinContainer" : "container"
      }`}
    >
      <div className="row justify-content-center support_wrapper h-100">
        <div
          className={`${
            currentTab == "subscribe" ? "billinPageMargin" : "copyTrade"
          }  mx-auto ${
            user?.firstVisit &&
            currentTab === "subscribe" &&
            "left-adjustment-copyTrade"
          }`}
        >
          <Tabs
            defaultActiveKey="connection"
            id="uncontrolled-tab-example"
            className="copyTradeTab d-none"
            activeKey={currentTab}
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="introduction"
              title={<div className="d-flex align-items-center"></div>}
            >
              <Introduction setTab={handleCurrentTab} />
            </Tab>
            <Tab
              eventKey="connection"
              title={<div className="d-flex align-items-center"></div>}
            >
              <Connection setTab={handleCurrentTab} />
            </Tab>
            <Tab
              eventKey="subscribe"
              className="subscribe-tab"
              title={<div className="d-flex align-items-center"></div>}
              disabled={!user?.onboarding?.connect}
            >
              <Subscribe setCommunity={setCommunity} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Index;
