import React, { useEffect, useState } from "react";
import Check from "../../../assets/images/check1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setBinanceConnecting,
  setLoading,
  setSigningYouIn,
} from "../../../redux/loading/loadingSlice";
import LoadingModal from "../../../components/loader/loadingModal";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Footer from "../../../components/footer/Footer";
import Secure from "../../../assets/images/3d-secure.png";
import MasterCard from "../../../assets/images/MasterCard.png";
import VisaVerified from "../../../assets/images/VisaVerified.png";
import paymentOptions from "../../../assets/images/payment-options.svg";
import { socailSignUp } from "../../../redux/socialSignup/socialSignUp";
import Discord from "../../../assets/images/discord.svg";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { setShowSidebar } from "../../../redux/sidebar/sidebarSlice";
import Telegram from "../../../assets/images/telegram.jpg";
import "../../auth/signin.css";

const handleClick = () => {
  window.scrollTo(0, 0);
};

export default function CommunityDashboard() {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [community, setCommunity] = useState();
  const { communityName } = useParams();
  const user = useSelector((state) => state?.user?.user);
  const [loader, setLoader] = useState(false);
  const socialSignUp = useSelector((state) => state?.socialSignUp?.value);

  const getCommunity = async () => {
    dispatch(setLoading(false));

    setLoader(true);

    const response = await api("post", "community/getCommunityByName", {
      name: communityName,
    });

    if (response?.status) {
      setCommunity(response?.data?.found);
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const logOut = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("lastVisitedRoute");
    // localStorage.removeItem("rememberMe")
    localStorage.clear();
    dispatch(
      storeUser({
        user: null,
        token: null,
      })
    );
    dispatch(socailSignUp(null));
  };

  const loginWithBinance = async () => {
    dispatch(setBinanceConnecting(true));
    dispatch(
      socailSignUp({
        role: "member",
        connecting: true,
        community: community,
      })
    );
    localStorage.setItem("AllowSocialLogin", JSON.stringify(true));
    const client_id = "39vZ9un1Fd";
    const redirect_uri = "https%3A%2F%2Fmy.copyyy.it%2Fsignin";
    const scope = "user:openId,create:apikey";
    const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`;
    window.location.href = cUrl;
  };

  const handleTestButton = () => {
    dispatch(setBinanceConnecting(true));
    dispatch(
      socailSignUp({
        role: "member",
        connecting: true,
        community: community,
      })
    );
    const clientId = "3ccf41551815af4";
    const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
    window.location.href = cUrl;
  };

  useEffect(() => {
    getCommunity();
    logOut();
  }, []);

  const handleJoining = async () => {
    if (!community) {
      toastify("Community not found!");
      return;
    }
    if (user && user?.role === "member") {
      if (user?.memberCommunities.length > 0) {
        toastify("You can only join one community.");
        return;
      }
      let allCommunities = [...user?.memberCommunities];
      let found = allCommunities.find(
        (e) => e?.community?._id?.toString() === community?._id?.toString()
      );

      if (!found) {
        let arr = [...allCommunities];
        arr.push({
          community: community?._id,
          subscribed: false,
        });
        allCommunities = arr;
        const response = await api("post", "users/update", {
          memberCommunities: allCommunities,
        });
        if (response?.status) {
          dispatch(updateUser(response?.data));
          navigate("/member/copyTrade", {
            state: { tab: "subscribe" },
          });
        }
      } else {
        navigate("/member/copyTrade", {
          state: { tab: "subscribe" },
        });
      }
    } else {
      navigate("/member/signup", {
        state: community,
      });
    }
  };

  const loginWithDiscord = async () => {
    const stateData = {
      community,
    };

    const encodedState = btoa(JSON.stringify(stateData));

    const discordOAuthURL = `${process.env.REACT_APP_DISCORD_OAUTH_URL}&state=${encodedState}`;
    console.log("discordOAuthURL", discordOAuthURL);

    window.location.href = discordOAuthURL;
  };

  const loginWithTelegram = async (data) => {
    console.log("loginWithTelegram", data);

    const response = await api("post", `fastSignupLogin/Telegram`, {
      telegramRes: data,
      community,
    });
    if (response?.status) {
      handleLoginSuccess(response);
    }
  };

  const handleLoginSuccess = (loginResponse) => {
    if (!loginResponse?.data?.user) return;

    const { user, token } = loginResponse.data;

    // Set initial state
    dispatch(setSigningYouIn(true));
    localStorage.setItem("token", token);
    dispatch(storeUser(loginResponse.data));
    dispatch(setShowSidebar(!user.firstVisit));

    // Handle navigation based on user role
    if (user.firstVisit) {
      const destination = `/${user.role}/copyTrade`;
      navigate(destination, { replace: true });

      // Set current tab based on user role
      const defaultTab = user.role === "leader" ? "subscription" : "connection";
      dispatch(setCurrentTab(user.onboardingStep || defaultTab));
    } else {
      navigate(`/${user.role}/dashboard`, { replace: true });
    }
  };

  const telegramredirecturl = () => {
    const telegramOAuthURL = process.env.REACT_APP_TELEGRAM_OAUTH_URL;
    window.location.href = telegramOAuthURL;
  };

  useEffect(() => {
    // Check if we have auth data in URL when component loads
    const hash = window.location.hash;
    if (hash && hash.includes("tgAuthResult=")) {
      // Get the encoded data from URL
      const base64Data = hash.split("tgAuthResult=")[1];
      try {
        // Decode base64 and parse JSON without using escape()
        const decodedBase64 = atob(base64Data);
        const decodedData = decodeURIComponent(decodedBase64);
        const telegramData = JSON.parse(decodedData);

        // Log the decoded data
        console.log("Telegram user data:", telegramData);

        loginWithTelegram(telegramData);

        // Clear the hash from URL
        window.history.replaceState(null, null, window.location.pathname);
      } catch (error) {
        console.error("Error parsing Telegram data:", error);
      }
    }
  }, []);

  return (
    <>
      {loader && <LoadingModal />}
      <div className="wrapper">
        <div className="mobile_version_screen login_card common_style tradeScreen">
          <section className="text-center">
            {community?.subscription?.logo && (
              <img
                src={community?.subscription?.logo}
                className="community-logo"
                alt="logo"
              />
            )}
            <div className="text-center">
              {community ? (
                <span className="mb-27">{community?.name?.toUpperCase()}</span>
              ) : (
                <span className="mb-27 text-light">.</span>
              )}
            </div>
            <h2 className="mb-17 fs25 li_h30 bold">Join our copy trades now</h2>
            <p className="fs16">Get started in just 3 minutes!</p>
            <ul className="tradingList">
              <li>
                <img src={Check} alt="check" /> 100% automated{" "}
              </li>
              <li>
                <img src={Check} alt="check" /> No KYC
              </li>
              <li>
                <img src={Check} alt="check" /> Safe and secure
              </li>
              <li>
                <img src={Check} alt="check" /> Pay with credit card
              </li>
              <li>
                <img src={Check} alt="check" /> Cancel anytime
              </li>
            </ul>
            {/* <p className="bold fs16">
                            {`Members who joined 3 months ago have turned $1000 into $${community?.estimatedAmount || 0
                                }`}
                        </p> */}
            <p className="fs16 mt-25">
              {/* We are mainly trading: <br /> */}
              {community?.pair?.map((p, index) => (
                <React.Fragment key={index}>
                  {/* {p} */}
                  {index < community.pair.length - 1 && "  "}{" "}
                  {/* Add plus sign if not the last item */}
                </React.Fragment>
              ))}
            </p>
            <div className="mt-130">
              <button
                // style={{ width: "294px" }}
                className="newCreateButton"
                onClick={() => {
                  handleClick();
                  handleJoining();
                }}
              >
                Sign up with email
              </button>
            </div>

            <div className="mt-4">
              <button className="signinWhitebtn" onClick={telegramredirecturl}>
                <span className="toptag">Faster</span>
                <img src={Telegram} alt="discoard" />
                <span className="text">Sign up with Telegram </span>
              </button>
            </div>

            <div className="mt-4">
              <button className="signinWhitebtn" onClick={loginWithDiscord}>
                <span className="toptag">Faster</span>
                <img src={Discord} alt="discoard" />
                <span className="text">Sign up with Discord </span>
              </button>
            </div>

            {/* <div className="mt-4">
                            <button style={{ width: "294px" }}
                                className="newCreateButtonWhite"
                                onClick={loginWithBinance}
                            >
                                <span>Faster</span>
                                Sign up with <img src={binanceBlack} alt="binance" />
                            </button>
                        </div> */}
            {/* <div className="mt-4">
                            <button style={{ width: "294px" }}
                                className="newCreateButtonWhite"
                                onClick={handleTestButton}
                            >
                                <span>Faster</span>
                                Sign up with ByBit
                            </button>
                        </div> */}
            <div className="mt-2">
              <div className="login_footer">
                <img
                  className="payment-option-img"
                  src={paymentOptions}
                  alt="payment options"
                />
                <p className="fs10">Safe and Secure Payments Checkout</p>
              </div>
              <div className="d-flex gap-2 align-items-center justify-content-center">
                <img src={Secure} alt="3D-secure" width={21} height={16} />
                <img
                  src={MasterCard}
                  alt="master-card"
                  width={55}
                  height={39}
                />
                <img
                  src={VisaVerified}
                  alt="visa-verified"
                  height={27}
                  width={38}
                />
              </div>
            </div>
            <div className="testbuttonview">
              <div
                className="newSignLink testclass"
                //  onClick={handleTestButton}
                onClick={loginWithBinance}
              >
                test
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}
