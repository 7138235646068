import React, { useState, useEffect } from "react";
import useApi from "../../helpers/apiHelper/requestHelper";
import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const CreateSubAccount = () => {
  const api = useApi();

  const [subAccountCount, setSubAccountCount] = useState(1);
  const [fundLocation, setFundLocation] = useState("FUND");
  const [error, setError] = useState("");
  const [subAccounts, setSubAccounts] = useState([]);
  const [amount, setAmount] = useState(5);
  const [openPosition, setOpenPosition] = useState([]);
  const [subAccountLoadings, setSubAccountLoadings] = useState(false);
  const [binanceApiLoading, setBinanceApiLoading] = useState(false);
  const [bybitApiLoading, setBybitApiLoading] = useState(false);
  const [openBinancePosition, setOpenBinancePosition] = useState([]);
  const [binanceSubAccounts, setBinanceSubAccounts] = useState([
    { name: `subAccount 1`, apiKey: "", secretKey: "" },
  ]);

  // List of possible fund locations
  const fundOptions = [
    "CONTRACT",
    "SPOT",
    "INVESTMENT",
    "OPTION",
    "UNIFIED",
    "FUND",
  ];

  // Handle the API call
  const callApi = async () => {
    // Check if the sub account count is valid
    if (subAccountCount < 1 || subAccountCount > 15) {
      setError("Sub-account count must be between 1 and 15.");
      return;
    }

    // Reset error if input is valid
    setError("");

    // Call the API with the data (subAccountCount and fundLocation)
    try {
      await api("post", "SubAccount/createBybitAccount", {
        subAccountNumber: subAccountCount,
        fromAccount: fundLocation,
        amount,
      });
      getAllSubAccounts();
      alert("Sub-accounts created successfully!");
    } catch (error) {
      alert("Error creating sub-accounts!");
      console.error(error);
    }
  };

  // Handle input changes for sub account count
  const handleSubAccountChange = (e) => {
    let value = parseInt(e.target.value, 10);
    if (value < 1) value = 1;
    if (value > 15) value = 15;
    setSubAccountCount(value);
  };

  // Handle the dropdown change for fund location
  const handleFundLocationChange = (e) => {
    setFundLocation(e.target.value);
  };

  const getAllSubAccounts = async () => {
    setSubAccountLoadings(true);
    const res = await api(
      "post",
      "SubAccount/getAllSubAccountRegisterOnCopyit"
    );
    setSubAccounts(res?.data);
    setSubAccountLoadings(false);
  };

  useEffect(() => {
    getAllSubAccounts();
    getSubAccountsTrade();
    getBinanceSubAccountsTrades();
  }, []);

  const allSubAccountTransfer = async () => {
    await api("post", "SubAccount/reTranserAmountFromSUbAccountToMainAccount");
    getAllSubAccounts();
  };

  const transferSpecificAccountAmount = async (email) => {
    await api("post", "SubAccount/transferSpecificAccountAmount", {
      email,
    });
    getAllSubAccounts();
  };

  const deleteSpecificAccount = async (email) => {
    await api("post", "SubAccount/deleteSpecificAccount", {
      email,
    });
    getAllSubAccounts();
  };

  const deleteAllSUbAccounts = async () => {
    await api("post", "SubAccount/deleteAllUsers");
    getAllSubAccounts();
  };

  const getBinanceSubAccountsTrades = async () => {
    setBinanceApiLoading(true);
    const res2 = await api("post", "SubAccount/getSubAccountsBinanceTrade");
    setOpenBinancePosition(res2?.data);
    setBinanceApiLoading(false);
  };

  const getSubAccountsTrade = async () => {
    setBybitApiLoading(true);
    const res = await api("post", "SubAccount/getSubAccountsTrade");
    setOpenPosition(res?.data);
    setBybitApiLoading(false);
  };

  const handleChange = (index, event) => {
    const values = [...binanceSubAccounts];
    values[index][event.target.name] = event.target.value;
    setBinanceSubAccounts(values);
  };

  // Handle click of "Add Row" button to add a new set of API Key & Secret Key fields
  const handleAddRow = () => {
    const newAccountName = `subAccount ${binanceSubAccounts.length + 1}`;
    setBinanceSubAccounts([
      ...binanceSubAccounts,
      { name: newAccountName, apiKey: "", secretKey: "" },
    ]);
  };

  // Handle form submission and call API with all subAccount credentials
  const handleSubmit = async () => {
    let validData = binanceSubAccounts.filter(
      (entry) => entry.apiKey && entry.secretKey
    );

    validData = validData.map((entry) => ({
      name: entry.name,
      apiKey: entry.apiKey,
      secretKey: entry.secretKey,
    }));

    for (let i = 0; i < validData.length; i++) {
      const { apiKey, secretKey } = validData[i];
      const subAccountNumber = i + 1;

      const result = await createBinanceSubAccount(
        apiKey,
        secretKey,
        subAccountNumber
      );
    }
    setBinanceSubAccounts([
      { name: `subAccount 1`, apiKey: "", secretKey: "" },
    ]);
  };

  const createBinanceSubAccount = async (
    apiKey,
    secretKey,
    subAccountNumber
  ) => {
    const res = await api("post", "SubAccount/createBinanceAccountonCopyyit", {
      subAccountApiKey: apiKey,
      subAccountApiSecretKey: secretKey,
      subAccountNumber,
    });

    console.log("createBinanceSubAccount", res);

    return res?.data;
  };

  const subAccountColumns = useMemo(
    () => [
      {
        accessorKey: "subAccount.apiRes.createSubMember.result.uid",
        header: "UID",
        size: 150,
        filterVariant: "autocomplete",
        Cell: ({ row }) => {
          const UID =
            row?.original?.subAccount?.apiRes?.createSubMember?.result?.uid ||
            "created Manually";
          return <>{UID}</>;
        },
      },
      {
        accessorKey: "subAccount.apiRes.createSubMember.result.username",
        header: "Username",
        filterVariant: "autocomplete",
        size: 150,
        Cell: ({ row }) => {
          const username =
            row?.original?.subAccount?.apiRes?.createSubMember?.result
              ?.username || "created Manually";
          return <>{username}</>;
        },
      },
      {
        accessorKey: "user.email",
        header: "User Email	",
        filterVariant: "autocomplete",
        size: 200,
        Cell: ({ row }) => {
          const email = row?.original?.email;
          return <>{email}</>;
        },
      },
      {
        accessorKey: "subAccount.apiRes.createSubUIDAPIKey.result.apiKey",
        header: "API Key",
        filterVariant: "autocomplete",
        size: 200,
        Cell: ({ row }) => {
          const apiKey =
            row?.original?.subAccount?.apiRes?.createSubUIDAPIKey?.result
              ?.apiKey || "created Manually";

          return <>{apiKey}</>;
        },
      },
      {
        accessorKey: "subAccount.apiRes.createSubUIDAPIKey.result.secret",
        header: "API Secret",
        size: 150,
        Cell: ({ row }) => {
          const sKey =
            row?.original?.subAccount?.apiRes?.createSubUIDAPIKey?.result
              ?.secret || "created Manually";

          return <>{sKey}</>;
        },
      },
      {
        accessorKey: "subAccount.apiRes.transferResult.result.status",
        header: "Status",
        filterVariant: "autocomplete",
        size: 200,
        Cell: ({ row }) => {
          const status =
            row?.original?.subAccount?.apiRes?.transferResult?.result?.status ||
            "created Manually";

          return <>{status}</>;
        },
      },
      {
        accessorKey: "subAccount.apiRes.transferResult.amount",
        header: "Amount",
        filterVariant: "autocomplete",
        size: 200,
        Cell: ({ row }) => {
          const amount =
            row?.original?.subAccount?.apiRes?.transferResult?.amount ||
            "created Manually";

          return <>{amount}</>;
        },
      },

      {
        accessorKey: "transfer",
        header: "Transfer Button",
        size: 200,
        Cell: ({ row }) => {
          const createdBySystem = row?.original?.subAccount?.apiRes;

          return (
            createdBySystem && (
              <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  transferSpecificAccountAmount(row?.original?.email);
                }}
              >
                Transfer
              </button>
            )
          );
        },
      },

      {
        accessorKey: "delete",
        header: "Delete Account",
        size: 200,
        Cell: ({ row }) => {
          const createdBySystem = row?.original?.subAccount?.apiRes;

          return (
            createdBySystem && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  deleteSpecificAccount(row?.original?.email);
                }}
              >
                Delete
              </button>
            )
          );
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "user.email",
        header: "Email",
        size: 150,
        filterVariant: "autocomplete",
        Cell: ({ row }) => {
          const email = row?.original?.user?.email;
          return <>{email}</>;
        },
      },
      {
        accessorKey: "position.symbol",
        header: "Asset",
        filterVariant: "autocomplete",
        size: 150,
        Cell: ({ row }) => {
          const asset = row?.original?.position?.symbol;
          return <>{asset}</>;
        },
      },
      {
        accessorKey: "position.side",
        header: "Direction",
        filterVariant: "autocomplete",
        size: 200,
        Cell: ({ row }) => {
          const side = row?.original?.position?.side;
          return <>{side}</>;
        },
      },
      {
        accessorKey: "position.updatedTime",
        header: "Open",
        size: 150,
        Cell: ({ row }) => {
          const time =
            row?.original?.position?.updatedTime ||
            row?.original?.position?.updateTime;

          const createdTime = new Date(parseInt(time));

          const formattedTime = createdTime.toLocaleString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          return <>{formattedTime}</>;
        },
      },
    ],
    []
  );

  const commnTableProps = {
    enableFacetedValues: true,
    initialState: {
      density: "compact",
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
    enableGrouping: true,
  };

  const bybitTradesTable = useMaterialReactTable({
    columns,
    data: openPosition || [],
    ...commnTableProps,
    state: {
      isLoading: bybitApiLoading,
      showProgressBars: bybitApiLoading,
    },
  });

  const binanceTradesTable = useMaterialReactTable({
    columns,
    data: openBinancePosition || [],
    ...commnTableProps,
    state: {
      isLoading: binanceApiLoading,
      showProgressBars: binanceApiLoading,
    },
  });

  const subAccountsTable = useMaterialReactTable({
    columns: subAccountColumns,
    data: subAccounts || [],
    ...commnTableProps,
    initialState: {
      density: "compact",
    },
    state: {
      isLoading: subAccountLoadings,
      showProgressBars: subAccountLoadings,
    },
  });

  return (
    <div className="container mt-5">
      <h2>Register Binance Sub Account on Copyyit</h2>
      <div>
        {binanceSubAccounts.map((account, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <label>
              {account.name}
              <input
                type="text"
                name="apiKey"
                placeholder={`API Key ${index + 1}`}
                value={account.apiKey}
                onChange={(e) => handleChange(index, e)}
                style={{ marginRight: "10px", marginBottom: "10px" }}
                className="form-control"
              />
              <input
                type="text"
                className="form-control"
                name="secretKey"
                placeholder={`Secret Key ${index + 1}`}
                value={account.secretKey}
                onChange={(e) => handleChange(index, e)}
              />
            </label>
          </div>
        ))}
        <button
          onClick={handleAddRow}
          className="btn btn-primary"
          style={{ marginBottom: "20px" }}
        >
          + Add Row
        </button>
        <div>
          <button onClick={handleSubmit} className="btn btn-primary mb-2">
            Submit
          </button>
        </div>
      </div>

      <h3>Create Bybit Sub Account</h3>
      <div className="form-group">
        <label>How many sub-accounts do you want to add?</label>
        <input
          type="number"
          className="form-control"
          value={subAccountCount}
          onChange={handleSubAccountChange}
        />
      </div>

      <div className="form-group">
        <label>How much amount you want to transfer</label>
        <input
          type="number"
          className="form-control"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
      </div>

      <div className="form-group">
        <label>Where is your fund available in the Master Account?</label>
        <select
          className="form-control"
          value={fundLocation}
          onChange={handleFundLocationChange}
        >
          {fundOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      {/* Error message display */}
      {error && <div className="alert alert-danger">{error}</div>}

      <button className="btn btn-primary mt-3" onClick={callApi}>
        Save
      </button>

      <div className="container ">
        <button
          className="btn btn-primary mt-3"
          onClick={allSubAccountTransfer}
        >
          Tranfer All Sub Account Amount in to Main Account
        </button>
        <br />
        <button className="btn btn-primary mt-3" onClick={deleteAllSUbAccounts}>
          Delete All Sub Accounts
        </button>
        <h3
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Register Sub Account User Listining on Copyyit
        </h3>
        <MaterialReactTable table={subAccountsTable} />

        <h3
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Bybit Trades
        </h3>
        <MaterialReactTable table={bybitTradesTable} />
        <h3
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Binance Trades
        </h3>
        <MaterialReactTable table={binanceTradesTable} />
      </div>
    </div>
  );
};

export default CreateSubAccount;
