import React, { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import SideBar from "./SideBar";
import "./NewLayout.css";
import useApi from "../helpers/apiHelper/requestHelper";
import { useDispatch } from "react-redux";
import { storeUser } from "../redux/users/usersSlice";
import { setShowSidebar } from "../redux/sidebar/sidebarSlice";
import { setCurrentTab } from "../redux/links/linksSlice";

const NewLayout = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const role = searchParams.get("role");
  const page = searchParams.get("page");

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        localStorage.setItem("token", token);
        const user = await fetchUserData();
        if (role === "leader" && page === "copyTrade") {
          dispatch(setCurrentTab(user?.user?.onboardingStep || "subscription"));
        }
        if (role === "member" && page === "copyTrade") {
          dispatch(setCurrentTab(user?.user?.onboardingStep || "connection"));
        }
        navigate(`/${role}/${page}`);
        dispatch(setShowSidebar(page === "dashboard"));
      }
    };

    fetchData();
  }, []);

  const fetchUserData = async () => {
    const response = await api("get", "users/index");
    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }
    return response?.data;
  };

  return (
    <div className="newLayoutBox">
      <div className="sideBarBox">
        <SideBar newLayout={true} />
      </div>
      <div className="mainContentBox">
        <Outlet />
      </div>
    </div>
  );
};

export default NewLayout;
